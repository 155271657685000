<template>
  <div v-if="items?.length">
    <template v-if="placement === 'side'">
      <CommonListLabel
        :icon="CalendarIcon"
        :label="title"
        :link="`${currentPaperUrl}${$t('Routes.Calendar')}/${
          type ? '?type=' + type : ''
        }`"
      />
      <CommonDivider />
    </template>

    <div
      v-if="placement === 'side'"
      v-for="(date, label) in itemsGroupedByDate"
      :key="label"
    >
      <div
        :class="true ? 'bg-blue' : 'bg-red'"
        class="mb-4 rounded px-2 py-1 text-white inline-block label-s uppercase"
      >
        {{ label }}
      </div>
      <template v-for="item in date" :key="item.RecordId">
        <NuxtLink
          :to="{
            name:
              indexStore.currentPaper?.RecordId === 1
                ? 'calendar'
                : 'paper_calendar',
            params:
              indexStore.currentPaper?.RecordId === 1
                ? {
                    id: item.RecordId,
                  }
                : {
                    paper: `${indexStore.currentPaper?.Url}`,
                    id: item.RecordId,
                  },
          }"
          class="block"
        >
          <div class="list-title-xxxs text-black">{{ item.Headline }}</div>
          <div class="list-label mt-2 flex opacity-50 items-center">
            <img
              :src="ClockIcon"
              class="w-4 h-4 inline-block mr-1"
              alt="Clock"
            />
            <span>{{ getTime(item.Time) }}</span>
            <img
              v-if="item.Place || item.City"
              :src="LocationIcon"
              :alt="$t('Addresse')"
              class="w-4 h-4 ml-4 inline-block mr-1"
            />
            <span>{{ formatLocation(item) }}</span>
          </div>
        </NuxtLink>
        <CommonDivider />
      </template>
    </div>

    <div
      v-else-if="placement === 'main'"
      v-for="(
        monthItemsByDate, yearAndMonthKey
      ) in dailyItemsGroupedByYearAndMonth"
      :key="`month-${yearAndMonthKey}`"
      class="mb-8"
    >
      <div class="list-title-l capitalize">
        {{ getMonth(Object.values(monthItemsByDate)[0][0].Time)
        }}<span class="font-light"
          >&nbsp;{{ getYear(Object.values(monthItemsByDate)[0][0].Time) }}</span
        >
      </div>
      <div v-for="dateItems in monthItemsByDate" class="mb-6">
        <div
          :class="true ? 'text-blue' : 'text-red'"
          class="my-3 rounded list-title-s !font-light"
        >
          {{ getDate(dateItems?.[0].Time) }}
        </div>
        <NuxtLink
          v-for="item in dateItems"
          :key="`calendarItem-${item.RecordId}`"
          :to="{
            name:
              indexStore.currentPaper?.RecordId === 1
                ? 'calendar'
                : 'paper_calendar',
            params:
              indexStore.currentPaper?.RecordId === 1
                ? {
                    id: item.RecordId,
                  }
                : {
                    paper: `${indexStore.currentPaper?.Url}`,
                    id: item.RecordId,
                  },
          }"
          class="bg-white border border-gray-300 rounded-xl mb-3 md:-ml-3 md:-mr-3 p-3 block"
        >
          <div class="list-title-xs !font-light mb-1">
            {{ getTime(item.Time) }}
          </div>
          <div class="list-title-xs">{{ item.Headline }}</div>
          <div class="list-label mt-3 flex opacity-50 items-center">
            <img
              :src="LocationIcon"
              :alt="$t('Addresse')"
              class="w-4 h-4 inline-block mr-1"
            /><span>{{ formatLocation(item) }}</span>
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CalendarIcon from '~/assets/icons/kalender.svg?url'
import ClockIcon from '~/assets/icons/clock.svg?url'
import LocationIcon from '~/assets/icons/location.svg?url'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'

import type { ContentCalendar } from '~/typesManual/content_api/calendar'

const nuxtApp = useNuxtApp()
const route = useRoute()
const config = useRuntimeConfig()

const indexStore = useIndexStore()

const props = withDefaults(
  defineProps<{
    placement?: 'main' | 'side'
    count?: number
    title?: string
    type?: string
  }>(),
  {
    placement: 'side',
    count: 50,
    title: 'PoliticalCalendar',
  }
)

const currentPaperUrl = computed(() => {
  return indexStore.currentPaper?.RecordId === 1
    ? '/'
    : `/${indexStore.currentPaper?.Url}/`
})

const dailyItemsGroupedByYearAndMonth = computed(() => {
  if (!Array.isArray(items.value) || props.placement !== 'main') return

  const itemsGroupedByYearAndMonth = groupBy(
    items.value,
    (item) => item.Time.getFullYear() * 12 + item.Time.getMonth()
  )

  return mapValues(itemsGroupedByYearAndMonth, (monthItems) =>
    groupBy(monthItems, (item) => item.Time.getDate())
  )
})

const itemsGroupedByDate = computed(() => {
  if (!Array.isArray(items.value) || props.placement !== 'side') return

  return groupBy(items.value, (item) => getDate(item.Time))
})

function formatLocation(item: ContentCalendar) {
  return (
    [item.Place, item.City].filter((x) => Boolean(x)).join(', ') || undefined
  )
}

function getDate(date: Date) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  if (props.placement !== 'side') return formatDate(date, true)

  if (date.toDateString() === today.toDateString()) {
    return nuxtApp.$t('Today')
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return nuxtApp.$t('Tomorrow')
  }

  return formatDate(date, false)
}

function getYear(date: Date) {
  return date.toLocaleDateString(config.public.site.localeCode, {
    year: 'numeric',
    timeZone: 'UTC',
  })
}

function getMonth(date: Date) {
  return date.toLocaleDateString(config.public.site.localeCode, {
    month: 'long',
    timeZone: 'UTC',
  })
}

function getTime(date: Date) {
  return date.toLocaleTimeString(config.public.site.localeCode, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  })
}

const { data: items } = await useAsyncData(
  `Calendar-${props.count}-${props.type}-${
    indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1
  }`,
  async () => {
    const queryType = route.query.type?.toString()

    return nuxtApp.$api.content.calendar({
      paperId: indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1,
      count: props.count,
      type: queryType ?? props.type,
    })
  }
)
</script>
